import React, { useState } from 'react';
import './css/legalDisclaimer.css';
import { useTranslation } from 'react-i18next';

const LegalDisclaimerFswp = () => {
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleAgreeButtonClick = () => {
    if (checkboxChecked) {
      // Handle the user's agreement, for example, by closing the popup
      setShowPopup(false);
    } else {
      // Alert the user that they need to check the checkbox
      alert(t("legalDisclaimerAlert"));
    }
  };

  return (
    <div className="App">
      {showPopup && (
        <>
          <div className="popup-background"></div>
          <div className="popup">
            <h1 className='mt-1 font-extrabold'>{t("legalDisclaimerTitle")}</h1>
            <p className='mt-2'>{t("legalDisclaimerFswp")}</p>
            <p className='mt-1'>{t("legalDisclaimerFswp2")}</p>

            <div className='agree'>
              <div className="flex items-center checkbox-text">
                <input type="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange} />
                <p className='text-md'>{t("agreeTerms")}</p>
              </div>
              <div className='agreeButton'>
                <button onClick={handleAgreeButtonClick}>{t("agree")}</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LegalDisclaimerFswp;


