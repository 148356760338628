import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'; // Or use useNavigate if using React Router v6
import logo from '../images/logoNavBar.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import './css/Login.css';


function Login () {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(faEyeSlash);

    const handleToggle = () => {
        if (type==='password'){
           setIcon(faEye);
           setType('text')
        } else {
           setIcon(faEyeSlash)
           setType('password')
        }
     }

     const handleLogin = async () => {
        try {
            const response = await axios.post(`https://northern-pathways-backend.onrender.com/login`, {
                username,
                password
            });
    
            localStorage.setItem('token', response.data.token);
            navigate('/dashboard'); 
        } catch (error) {
            console.error('Error logging in', error);
            if (error.response) {
                // Server responded with a status other than 200 range
                alert(`Error: ${error.response.data.message}`);
            } else if (error.request) {
                // Request was made but no response received
                alert('No response from server. Please try again later.');
            } else {
                // Something else happened
                alert('Error: ' + error.message);
            }
        }
    };
    

    return (
        <>
            <div className='box'> 
                <div className='logo'>
                    <img src={logo} alt='Logo'></img>
                </div>
                <div className='input-wrapper'>
                    <input
                        type='text' // or type='email' if collecting an email address
                        name='username'
                        className='input-field'
                        value={username} // bind the value of the input field to the username state
                        onChange={(e) => setUsername(e.target.value)} // update the username state when the input changes
                        required
                    ></input>
                    <label
                        for='username'
                        className='input-label'>
                            Username
                    </label>
                <div className='input-wrapper'>
                    <input
                        type={type}
                        name='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        className='input-field'
                        required
                    ></input>
                    <label
                        for='password'
                        className='input-label'>
                            Password
                    </label>
                    <div className='eye-icon' onClick={handleToggle}>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                </div>

                <button className='login-btn' onClick={handleLogin}>
                    Login
                </button>

            </div>

            </div>
        </>
    )
}

export default Login;