import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Document, Page, Text, PDFDownloadLink, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import {Table, TR, TH, TD} from '@ag-media/react-pdf-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FaTimes } from 'react-icons/fa';
import './css/scoreCalculator.css';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight } from 'react-icons/ai';

import backgroundImage from '../images/template.png'; // Adjust the path as needed

Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  });

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      display: 'flex',
      fontFamily: "Roboto"
    },
    content: {
      padding: 20,
      backgroundColor: 'transparent',
      color: '#000000',
      width: '100%',
      display: 'flex',
    },
    tableOne: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '60px',
      justifyContent: 'space-between'
    },
    tables: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px'
    },
    tableD: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '30px'
    },
    titles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '5px',
        fontWeight: 'bold'
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1
    },
    copyright: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      alignItems: 'center'
    }
});

const DetailsPDFDocument = ({
    agePoint,
    educationPoint,
    caWorkExperiencePoint,
    sectionA,
    speakingPoint,
    listeningPoint,
    readingPoint,
    writingPoint,
    totalFirstLanguage,
    speakingPointSecond,
    listeningPointSecond,
    readingPointSecond,
    writingPointSecond,
    totalSecondLanguage,
    spouseEducationPoint,
    spouseWorkStatusPoint,
    sectionB,
    speakingPointSpouse,
    listeningPointSpouse,
    readingPointSpouse,
    writingPointSpouse,
    totalSpouseFirstLanguage,
    firstPart1SectionC,
    secondPart1SectionC,
    sectionCPart1,
    firstPart2SectionC,
    secondPart2SectionC,
    sectionCPart2,
    certificateSectionC,
    sectionC,
    lawPartnerPoint,
    NCLCPoint,
    canadaPostSecondaryPoint,
    employmentPoint,
    nominationPoint,
    sectionD,
    totalPointSections,
    date,
    maxAgePoint,
    maxEducationPoint,
    maxFirstLanguagePoint,
    maxSecondLanguagePoint,
    maxWorkExperiencePoint
}) => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();
    return (
        <Document>
            <Page size="Letter" style={styles.page}>
                {/* Background Image */}
                <Image src={backgroundImage} style={styles.backgroundImage} />
                
                {/* Main content */}
                <View style={styles.content}>

                    <View style={styles.titles}> 
                        <Text style={{
                            fontSize: '12px',
                            display: 'flex',
                            marginLeft: '500px'
                        }}> {date} </Text>
                    </View>

                    {/* Section A */}
                    <View style={styles.tableOne}> 
                        <View style={{ borderBottomWidth: 1, marginLeft: 110}}>
                            <Text style={{
                                fontSize: 12
                            }}>{t("sectionA")}</Text>
                        </View>

                        <View style={{ borderBottomWidth: 1, marginRight: 135 }}>
                            <Text style={{
                                fontSize: 12
                            }}>{t("sectionB")}</Text>
                        </View>
                    </View>

                    <View style={styles.tables}> 
                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '95%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>
                                    {t("age")}
                                </TD>
                                <TD weighting={0.13}>{agePoint}/{maxAgePoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("education")}</TD>
                                <TD weighting={0.13}>{educationPoint}/{maxEducationPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("caWork")}</TD>
                                <TD weighting={0.13}>{caWorkExperiencePoint}/{maxWorkExperiencePoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("firstLanguageTotal")}</TD>
                                <TD weighting={0.13}>{totalFirstLanguage}/{maxFirstLanguagePoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("secondLanguageTotalTable")}</TD>
                                <TD weighting={0.13}>{totalSecondLanguage}/{maxSecondLanguagePoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#ffacac',
                                width: '95%'
                            }}>
                                <TD>{t("sectionAtotal")}</TD>
                                <TD weighting={0.13}>{sectionA}</TD>
                            </TR>
                        </Table>

                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '95%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("spouseEducation")}</TD>
                                <TD weighting={0.13}>{spouseEducationPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("spouseWorkExperience")}</TD>
                                <TD weighting={0.13}>{spouseWorkStatusPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("spouseLanguageTotal")}</TD>
                                <TD weighting={0.13}>{totalSpouseFirstLanguage}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#ffacac',
                                width: '95%'
                            }}>
                                <TD>{t("sectionBtotal")}</TD>
                                <TD weighting={0.13}>{sectionB}</TD>
                            </TR>
                        </Table>
                    </View>

                    <View style={styles.titles}> 
                        <View style={{width: '50%'}}>
                            <Text style={{
                                fontSize: 10
                            }}>{t("firstLanguage")}</Text>
                        </View>

                        <View style={{width: '50%'}}>
                            <Text style={{
                                fontSize: 10
                            }}>{t("spouseLanguage")}</Text>
                        </View>
                    </View>

                    <View style={styles.tables}> 
                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '95%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("speaking")}</TD>
                                <TD weighting={0.13}>{speakingPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("listening")}</TD>
                                <TD weighting={0.13}>{listeningPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("reading")}</TD>
                                <TD weighting={0.13}>{readingPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%'
                            }}>
                                <TD>{t("writing")}</TD>
                                <TD weighting={0.13}>{writingPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#fcd4d4',
                                width: '95%'
                            }}>
                                <TD>{t("firstLanguageTotal")}</TD>
                                <TD weighting={0.13}>{totalFirstLanguage}</TD>
                            </TR>
                        </Table>

                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '95%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("speaking")}</TD>
                                <TD weighting={0.13}>{speakingPointSpouse}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("listening")}</TD>
                                <TD weighting={0.13}>{listeningPointSpouse}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("reading")}</TD>
                                <TD weighting={0.13}>{readingPointSpouse}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("writing")}</TD>
                                <TD weighting={0.13}>{writingPointSpouse}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#fcd4d4',
                                width: '95%'
                            }}>
                                <TD>{t("spouseLanguageTotal")}</TD>
                                <TD weighting={0.13}>{totalSpouseFirstLanguage}</TD>
                            </TR>
                        </Table>
                    </View>

                    <View style={styles.titles}> 
                        <View style={{}}>
                            <Text style={{
                                fontSize: 10,
                            }}>{t("secondLanguage")}</Text>
                        </View>
                    </View>

                    <View style={styles.tables}> 
                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '47.5%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '47.5%'
                            }}>
                                <TD>{t("speaking")}</TD>
                                <TD weighting={0.13}>{speakingPointSecond}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '47.5%'
                            }}>
                                <TD>{t("listening")}</TD>
                                <TD weighting={0.13}>{listeningPointSecond}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '47.5%'
                            }}>
                                <TD>{t("reading")}</TD>
                                <TD weighting={0.13}>{readingPointSecond}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '47.5%'
                            }}>
                                <TD>{t("writing")}</TD>
                                <TD weighting={0.13}>{writingPointSecond}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#fcd4d4',
                                width: '47.5%'
                            }}>
                                <TD>{t("secondLanguageTotal")}</TD>
                                <TD weighting={0.13}>{totalSecondLanguage}</TD>
                            </TR>
                        </Table>
                    </View>

                    <View style={styles.titles}> 
                        <View style={{ borderBottomWidth: 1, marginLeft: 110}}>
                            <Text style={{
                                fontSize: 12
                            }}>{t("sectionC")}</Text>
                        </View>

                        <View style={{ borderBottomWidth: 1, marginRight: 135 }}>
                            <Text style={{
                                fontSize: 12
                            }}>{t("sectionD")}</Text>
                        </View>
                    </View>

                    <View style={styles.tables}> 
                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '95%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("educationAndLanguage")}</TD>
                                <TD weighting={0.13}>{firstPart1SectionC}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("workAndEducation")}</TD>
                                <TD weighting={0.13}>{secondPart1SectionC}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#fcd4d4',
                                width: '95%'
                            }}>
                                <TD>{t("sectionCpart1")}</TD>
                                <TD weighting={0.13}>{sectionCPart1}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("foreignWorkAndLanguage")}</TD>
                                <TD weighting={0.13}>{firstPart2SectionC}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("canadianAndForeign")}</TD>
                                <TD weighting={0.13}>{secondPart2SectionC}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#fcd4d4',
                                width: '95%'
                            }}>
                                <TD>{t("sectionCpart2")}</TD>
                                <TD weighting={0.13}>{sectionCPart2}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("qualificationCertificate")}</TD>
                                <TD weighting={0.13}>{certificateSectionC}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#ffacac',
                                width: '95%'
                            }}>
                                <TD>{t("sectionCtotal")}</TD>
                                <TD weighting={0.13}>{sectionC}</TD>
                            </TR>
                        </Table>

                        <Table 
                            tdStyle={{
                            padding: '2px',
                            }}>
                            <TH style={{
                                fontSize: 9,
                                backgroundColor: '#f2f2f2',
                                width: '95%'
                            }}>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }}>{t("category")}</TD>
                                <TD style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    justifyContent: 'flex-start'
                                }} weighting={0.13}>{t("points")}</TD>
                            </TH>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("sibling")}</TD>
                                <TD weighting={0.13}>{lawPartnerPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("frenchProf")}</TD>
                                <TD weighting={0.13}>{NCLCPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("postSecondary")}</TD>
                                <TD weighting={0.13}>{canadaPostSecondaryPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("arrangedEmployment")}</TD>
                                <TD weighting={0.13}>{employmentPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                width: '95%',
                                backgroundColor: 'white'
                            }}>
                                <TD>{t("nomination")}</TD>
                                <TD weighting={0.13}>{nominationPoint}</TD>
                            </TR>

                            <TR style={{
                                fontSize: 9,
                                backgroundColor: '#ffacac',
                                width: '95%'
                            }}>
                                <TD>{t("sectionDtotal")}</TD>
                                <TD weighting={0.13}>{sectionD}</TD>
                            </TR>
                        </Table>
                    </View>

                    <View style={styles.titles}> 
                        <Text style={{
                            fontSize: '12px'
                        }}> {t("totalScore")}: {totalPointSections} </Text>
                    </View>

                    <View style={styles.copyright}> 
                        <Text style={{
                            fontSize: '8px',
                        }}> &copy; {currentYear} Northern Pathways Immigration Consulting Inc. {t("copyright")}.</Text>
                    </View>

                </View>
            </Page>
        </Document>
)};


const ScoreCalculator = ({ maritalOption, partnerStatus, movingStatus, ageStatus, educationStatus, caEducationStatus, caEducationLevelStatus, languageTest, speakingTest, listeningTest, readingTest, writingTest, secondLanguageTest, speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, workStatus, foreignWorkStatus, certificateStatus, additionalStatus, nocStatus, nominationStatus, lawPartnerStatus, spouseEducationStatus, spouseWorkStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse}) => {
    const { t } = useTranslation();


    const [showResults, setShowResults] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const popUpAnimation = useSpring({
        opacity: showResults ? 1 : 0,
        transform: showResults ? 'translateY(0)' : 'translateY(-50%)',
    });

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const [sectionA, setSectionA] = useState(0);
    const [agePoint, setAgePoint] = useState(0);
    const [educationPoint, setEducationPoint] = useState(0);

    const [speakingPoint, setSpeakingPoint] = useState(0);
    const [listeningPoint, setListeningPoint] = useState(0);
    const [readingPoint, setReadingPoint] = useState(0);
    const [writingPoint, setWritingPoint] = useState(0);

    const [totalFirstLanguage, setTotalFirstLanguage] = useState(0);

    const [speakingPointSecond, setSpeakingPointSecond] = useState(0);
    const [listeningPointSecond, setListeningPointSecond] = useState(0);
    const [readingPointSecond, setReadingPointSecond] = useState(0);
    const [writingPointSecond, setWritingPointSecond] = useState(0);

    const [totalSecondLanguage, setTotalSecondLanguage] = useState(0);

    const [caWorkExperiencePoint, setCaWorkExperiencePoint] = useState(0);

    const [spouseEducationPoint, setSpouseEducationPoint] = useState(0);

    const [speakingPointSpouse, setSpeakingPointSpouse] = useState(0);
    const [listeningPointSpouse, setListeningPointSpouse] = useState(0);
    const [readingPointSpouse, setReadingPointSpouse] = useState(0);
    const [writingPointSpouse, setWritingPointSpouse] = useState(0);

    const [totalSpouseFirstLanguage, setTotalSpouseFirstLanguage] = useState(0);

    const [spouseWorkStatusPoint, setSpouseWorkStatusPoint] = useState(0);


    const sectionApointsSpouse = {
        age: {
            option1: 0,
            option2: 90,
            option3: 95,
            option4: 100,
            option5: 95,
            option6: 90,
            option7: 85,
            option8: 80,
            option9: 75,
            option10: 70,
            option11: 65,
            option12: 60,
            option13: 55,
            option14: 50,
            option15: 45,
            option16: 35,
            option17: 25,
            option18: 15,
            option19: 5,
            option20: 0,
        },
        education: {
            option1: 0,
            option2: 28,
            option3: 84,
            option4: 91,
            option5: 112,
            option6: 119,
            option7: 126,
            option8: 140,
        },
        languageTest: {
            option1: {
                speaking: {
                    '10-12': 32,
                    '9': 29,
                    '8': 22,
                    '7': 16,
                    '6': 8,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                },
                listening: {
                    '10-12': 32,
                    '9': 29,
                    '8': 22,
                    '7': 16,
                    '6': 8,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                },
                reading: {
                    '10-12': 32,
                    '9': 29,
                    '8': 22,
                    '7': 16,
                    '6': 8,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                },
                writing: {
                    '10-12': 32,
                    '9': 29,
                    '8': 22,
                    '7': 16,
                    '6': 8,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                }
            },
            option2: {
                speaking: {
                    '7.5-9.0': 32,
                    '7.0': 29,
                    '6.5': 22,
                    '6.0': 16,
                    '5.5': 8,
                    '5.0': 6,
                    '4.0-4.5': 6,
                    '0-3.5': 0
                },
                listening: {
                    '8.5-9.0': 32,
                    '8.0': 29,
                    '7.5': 22,
                    '6.0-7.0': 16,
                    '5.5': 8,
                    '5.0': 6,
                    '4.5': 6,
                    '0-4.0': 0
                },
                reading: {
                    '8.0-9.0': 32,
                    '7.0-7.5': 29,
                    '6.5': 22,
                    '6.0': 16,
                    '5.0-5.5': 8,
                    '4.0-4.5': 6,
                    '3.5': 6,
                    '0-3.0': 0
                },
                writing: {
                    '7.5-9.0': 32,
                    '7.0': 29,
                    '6.5': 22,
                    '6.0': 16,
                    '5.5': 8,
                    '5.0': 6,
                    '4.0-4.5': 6,
                    '0-3.5': 0
                }
            },
            option3: {
                speaking: {
                    '89+': 32,
                    '84-88': 29,
                    '76-83': 22,
                    '68-75': 16,
                    '59-67': 8,
                    '51-58': 6,
                    '42-50': 6,
                    '0-41': 0
                },
                listening: {
                    '89+': 32,
                    '82-88': 29,
                    '71-81': 22,
                    '60-70': 16,
                    '50-59': 8,
                    '39-49': 6,
                    '28-38': 6,
                    '0-27': 0
                },
                reading: {
                    '88+': 32,
                    '78-87': 29,
                    '69-77': 22,
                    '60-68': 16,
                    '51-59': 8,
                    '42-50': 6,
                    '33-41': 6,
                    '0-32': 0
                },
                writing: {
                    '90+': 32,
                    '88-89': 29,
                    '79-87': 22,
                    '69-78': 16,
                    '60-68': 8,
                    '51-59': 6,
                    '41-50': 6,
                    '0-40': 0
                }
            },
            option4: {
                speaking: {
                    '556-699': 32,
                    '518-555': 29,
                    '494-517': 22,
                    '456-493': 16,
                    '422-455': 8,
                    '387-421': 6,
                    '328-386': 6
                },
                listening: {
                    '546-699': 32,
                    '503-545': 29,
                    '462-502': 22,
                    '434-461': 16,
                    '393-433': 8,
                    '352-392': 6,
                    '306-351': 6
                },
                reading: {
                    '546-699': 32,
                    '503-545': 29,
                    '462-502': 22,
                    '434-461': 16,
                    '393-433': 8,
                    '352-392': 6,
                    '306-351': 6
                },
                writing: {
                    '558-699': 32,
                    '512-557': 29,
                    '472-511': 22,
                    '428-471': 16,
                    '379-427': 8,
                    '330-378': 6,
                    '268-329': 6
                }
            },
            option5: {
                speaking: {
                    '16-20': 32,
                    '14-15': 29,
                    '12-13': 22,
                    '10-11': 16,
                    '7-9': 8,
                    '6': 6,
                    '4-5': 6,
                    '0-3': 0
                },
                listening: {
                    '549-699': 32,
                    '523-548': 29,
                    '503-522': 22,
                    '458-502': 16,
                    '398-457': 8,
                    '369-397': 6,
                    '331-368': 6,
                    '0-330': 0
                },
                reading: {
                    '549-699': 32,
                    '524-548': 29,
                    '499-523': 22,
                    '453-498': 16,
                    '406-452': 8,
                    '375-405': 6,
                    '342-374': 6,
                    '0-341': 0
                },
                writing: {
                    '16-20': 32,
                    '14-15': 29,
                    '12-13': 22,
                    '10-11': 16,
                    '7-9': 8,
                    '6': 6,
                    '4-5': 6,
                    '0-3': 0
                }
            },
        },
        languageTestSecond: {
            option1: {
                speaking: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                listening: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                reading: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                writing: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                }
            },
            option2: {
                speaking: {
                    '7.5-9.0': 6,
                    '7.0': 6,
                    '6.5': 3,
                    '6.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                },
                listening: {
                    '8.5-9.0': 6,
                    '8.0': 6,
                    '7.5': 3,
                    '6.0-7.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.5': 0,
                    '0-4.0': 0
                },
                reading: {
                    '8.0-9.0': 6,
                    '7.0-7.5': 6,
                    '6.5': 3,
                    '6.0': 3,
                    '5.0-5.5': 1,
                    '4.0-4.5': 1,
                    '3.5': 0,
                    '0-3.0': 0
                },
                writing: {
                    '7.5-9.0': 6,
                    '7.0': 6,
                    '6.5': 3,
                    '6.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                }
            },
            option3: {
                speaking: {
                    '89+': 6,
                    '84-88': 6,
                    '76-83': 3,
                    '68-75': 3,
                    '59-67': 1,
                    '51-58': 1,
                    '42-50': 0,
                    '0-41': 0
                },
                listening: {
                    '89+': 6,
                    '82-88': 6,
                    '71-81': 3,
                    '60-70': 3,
                    '50-59': 1,
                    '39-49': 1,
                    '28-38': 0,
                    '0-27': 0
                },
                reading: {
                    '88+': 6,
                    '78-87': 6,
                    '69-77': 3,
                    '60-68': 3,
                    '51-59': 1,
                    '42-50': 1,
                    '33-41': 0,
                    '0-32': 0
                },
                writing: {
                    '90+': 6,
                    '88-89': 6,
                    '79-87': 3,
                    '69-78': 3,
                    '60-68': 1,
                    '51-59': 1,
                    '41-50': 0,
                    '0-40': 0
                }
            },
            option4: {
                speaking: {
                    '556-699': 6,
                    '518-555': 6,
                    '494-517': 3,
                    '456-493': 3,
                    '422-455': 1,
                    '387-421': 1,
                    '328-386': 0
                },
                listening: {
                    '546-699': 6,
                    '503-545': 6,
                    '462-502': 3,
                    '434-461': 3,
                    '393-433': 1,
                    '352-392': 1,
                    '306-351': 0
                },
                reading: {
                    '546-699': 6,
                    '503-545': 6,
                    '462-502': 3,
                    '434-461': 3,
                    '393-433': 1,
                    '352-392': 1,
                    '306-351': 0
                },
                writing: {
                    '558-699': 6,
                    '512-557': 6,
                    '472-511': 3,
                    '428-471': 3,
                    '379-427': 1,
                    '330-378': 1,
                    '268-329': 0
                }
            },
            option5: {
                speaking: {
                    '16-20': 6,
                    '14-15': 6,
                    '12-13': 3,
                    '10-11': 3,
                    '7-9': 1,
                    '6': 1,
                    '4-5': 0,
                    '0-3': 0
                },
                listening: {
                    '549-699': 6,
                    '523-548': 6,
                    '503-522': 3,
                    '458-502': 3,
                    '398-457': 1,
                    '369-397': 1,
                    '331-368': 0,
                    '0-330': 0
                },
                reading: {
                    '549-699': 6,
                    '524-548': 6,
                    '499-523': 3,
                    '453-498': 3,
                    '406-452': 1,
                    '375-405': 1,
                    '342-374': 0,
                    '0-341': 0
                },
                writing: {
                    '16-20': 6,
                    '14-15': 6,
                    '12-13': 3,
                    '10-11': 3,
                    '7-9': 1,
                    '6': 1,
                    '4-5': 0,
                    '0-3': 0
                }
            },
        },
        caWorkExperience: {
            option1: 0,
            option2: 35,
            option3: 46,
            option4: 56,
            option5: 63,
            option6: 70
        },
        spouseEducation: {
            option1: 0,
            option2: 2,
            option3: 6,
            option4: 7,
            option5: 8,
            option6: 9,
            option7: 10,
            option8: 10
        },
        languageTestSpouse: {
            option1: {
                speaking: {
                    '10-12': 5,
                    '9': 5,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                listening: {
                    '10-12': 5,
                    '9': 5,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                reading: {
                    '10-12': 5,
                    '9': 5,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                writing: {
                    '10-12': 5,
                    '9': 5,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                }
            },
            option2: {
                speaking: {
                    '7.5-9.0': 5,
                    '7.0': 5,
                    '6.5': 3,
                    '6.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                },
                listening: {
                    '8.5-9.0': 5,
                    '8.0': 5,
                    '7.5': 3,
                    '6.0-7.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.5': 0,
                    '0-4.0': 0
                },
                reading: {
                    '8.0-9.0': 5,
                    '7.0-7.5': 5,
                    '6.5': 3,
                    '6.0': 3,
                    '5.0-5.5': 1,
                    '4.0-4.5': 1,
                    '3.5': 0,
                    '0-3.0': 0
                },
                writing: {
                    '7.5-9.0': 5,
                    '7.0': 5,
                    '6.5': 3,
                    '6.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                }
            },
            option3: {
                speaking: {
                    '89+': 5,
                    '84-88': 5,
                    '76-83': 3,
                    '68-75': 3,
                    '59-67': 1,
                    '51-58': 1,
                    '42-50': 0,
                    '0-41': 0
                },
                listening: {
                    '89+': 5,
                    '82-88': 5,
                    '71-81': 3,
                    '60-70': 3,
                    '50-59': 1,
                    '39-49': 1,
                    '28-38': 0,
                    '0-27': 0
                },
                reading: {
                    '88+': 5,
                    '78-87': 5,
                    '69-77': 3,
                    '60-68': 3,
                    '51-59': 1,
                    '42-50': 1,
                    '33-41': 0,
                    '0-32': 0
                },
                writing: {
                    '90+': 5,
                    '88-89': 5,
                    '79-87': 3,
                    '69-78': 3,
                    '60-68': 1,
                    '51-59': 1,
                    '41-50': 0,
                    '0-40': 0
                }
            },
            option4: {
                speaking: {
                    '556-699': 5,
                    '518-555': 5,
                    '494-517': 3,
                    '456-493': 3,
                    '422-455': 1,
                    '387-421': 1,
                    '328-386': 0
                },
                listening: {
                    '546-699': 5,
                    '503-545': 5,
                    '462-502': 3,
                    '434-461': 3,
                    '393-433': 1,
                    '352-392': 1,
                    '306-351': 0
                },
                reading: {
                    '546-699': 5,
                    '503-545': 5,
                    '462-502': 3,
                    '434-461': 3,
                    '393-433': 1,
                    '352-392': 1,
                    '306-351': 0
                },
                writing: {
                    '558-699': 5,
                    '512-557': 5,
                    '472-511': 3,
                    '428-471': 3,
                    '379-427': 1,
                    '330-378': 1,
                    '268-329': 0
                }
            },
            option5: {
                speaking: {
                    '16-20': 5,
                    '14-15': 5,
                    '12-13': 3,
                    '10-11': 3,
                    '7-9': 1,
                    '6': 1,
                    '4-5': 0,
                    '0-3': 0
                },
                listening: {
                    '549-699': 5,
                    '523-548': 5,
                    '503-522': 3,
                    '458-502': 3,
                    '398-457': 1,
                    '369-397': 1,
                    '331-368': 0,
                    '0-330': 0
                },
                reading: {
                    '549-699': 5,
                    '524-548': 5,
                    '499-523': 3,
                    '453-498': 3,
                    '406-452': 1,
                    '375-405': 1,
                    '342-374': 0,
                    '0-341': 0
                },
                writing: {
                    '16-20': 5,
                    '14-15': 5,
                    '12-13': 3,
                    '10-11': 3,
                    '7-9': 1,
                    '6': 1,
                    '4-5': 0,
                    '0-3': 0
                }
            },
        },
        spouseWorkStatus: {
            option1: 0,
            option2: 5,
            option3: 7,
            option4: 8,
            option5: 9,
            option6: 10
        }
    };

    const sectionApointsWithoutSpouse = {
        age: {
            option1: 0,
            option2: 99,
            option3: 105,
            option4: 110,
            option5: 105,
            option6: 99,
            option7: 94,
            option8: 88,
            option9: 83,
            option10: 77,
            option11: 72,
            option12: 66,
            option13: 61,
            option14: 55,
            option15: 50,
            option16: 39,
            option17: 28,
            option18: 17,
            option19: 6,
            option20: 0,
        },
        education: {
            option1: 0,
            option2: 30,
            option3: 90,
            option4: 98,
            option5: 120,
            option6: 128,
            option7: 135,
            option8: 150,
        },
        languageTest: {
            option1: {
                speaking: {
                    '10-12': 34,
                    '9': 31,
                    '8': 23,
                    '7': 17,
                    '6': 9,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                },
                listening: {
                    '10-12': 34,
                    '9': 31,
                    '8': 23,
                    '7': 17,
                    '6': 9,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                },
                reading: {
                    '10-12': 34,
                    '9': 31,
                    '8': 23,
                    '7': 17,
                    '6': 9,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                },
                writing: {
                    '10-12': 34,
                    '9': 31,
                    '8': 23,
                    '7': 17,
                    '6': 9,
                    '5': 6,
                    '4': 6,
                    'M 0-3': 0
                }
            },
            option2: {
                speaking: {
                    '7.5-9.0': 34,
                    '7.0': 31,
                    '6.5': 23,
                    '6.0': 17,
                    '5.5': 9,
                    '5.0': 6,
                    '4.0-4.5': 6,
                    '0-3.5': 0
                },
                listening: {
                    '8.5-9.0': 34,
                    '8.0': 31,
                    '7.5': 23,
                    '6.0-7.0': 17,
                    '5.5': 9,
                    '5.0': 6,
                    '4.5': 6,
                    '0-4.0': 0
                },
                reading: {
                    '8.0-9.0': 34,
                    '7.0-7.5': 31,
                    '6.5': 23,
                    '6.0': 17,
                    '5.0-5.5': 9,
                    '4.0-4.5': 6,
                    '3.5': 6,
                    '0-3.0': 0
                },
                writing: {
                    '7.5-9.0': 34,
                    '7.0': 31,
                    '6.5': 23,
                    '6.0': 17,
                    '5.5': 9,
                    '5.0': 6,
                    '4.0-4.5': 6,
                    '0-3.5': 0
                }
            },
            option3: {
                speaking: {
                    '89+': 34,
                    '84-88': 31,
                    '76-83': 23,
                    '68-75': 17,
                    '59-67': 9,
                    '51-58': 6,
                    '42-50': 6,
                    '0-41': 0
                },
                listening: {
                    '89+': 34,
                    '82-88': 31,
                    '71-81': 23,
                    '60-70': 17,
                    '50-59': 9,
                    '39-49': 6,
                    '28-38': 6,
                    '0-27': 0
                },
                reading: {
                    '88+': 34,
                    '78-87': 31,
                    '69-77': 23,
                    '60-68': 17,
                    '51-59': 9,
                    '42-50': 6,
                    '33-41': 6,
                    '0-32': 0
                },
                writing: {
                    '90+': 34,
                    '88-89': 31,
                    '79-87': 23,
                    '69-78': 17,
                    '60-68': 9,
                    '51-59': 6,
                    '41-50': 6,
                    '0-40': 0
                }
            },
            option4: {
                speaking: {
                    '556-699': 34,
                    '518-555': 31,
                    '494-517': 23,
                    '456-493': 17,
                    '422-455': 9,
                    '387-421': 6,
                    '328-386': 6
                },
                listening: {
                    '546-699': 34,
                    '503-545': 31,
                    '462-502': 23,
                    '434-461': 17,
                    '393-433': 9,
                    '352-392': 6,
                    '306-351': 6
                },
                reading: {
                    '546-699': 34,
                    '503-545': 31,
                    '462-502': 23,
                    '434-461': 17,
                    '393-433': 9,
                    '352-392': 6,
                    '306-351': 6
                },
                writing: {
                    '558-699': 34,
                    '512-557': 31,
                    '472-511': 23,
                    '428-471': 17,
                    '379-427': 9,
                    '330-378': 6,
                    '268-329': 6
                }
            },
            option5: {
                speaking: {
                    '16-20': 34,
                    '14-15': 31,
                    '12-13': 23,
                    '10-11': 17,
                    '7-9': 9,
                    '6': 6,
                    '4-5': 6,
                    '0-3': 0
                },
                listening: {
                    '549-699': 34,
                    '523-548': 31,
                    '503-522': 23,
                    '458-502': 17,
                    '398-457': 9,
                    '369-397': 6,
                    '331-368': 6,
                    '0-330': 0
                },
                reading: {
                    '549-699': 34,
                    '524-548': 31,
                    '499-523': 23,
                    '453-498': 17,
                    '406-452': 9,
                    '375-405': 6,
                    '342-374': 6,
                    '0-341': 0
                },
                writing: {
                    '16-20': 34,
                    '14-15': 31,
                    '12-13': 23,
                    '10-11': 17,
                    '7-9': 9,
                    '6': 6,
                    '4-5': 6,
                    '0-3': 0
                }
            },
        },
        languageTestSecond: {
            option1: {
                speaking: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                listening: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                reading: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                },
                writing: {
                    '10-12': 6,
                    '9': 6,
                    '8': 3,
                    '7': 3,
                    '6': 1,
                    '5': 1,
                    '4': 0,
                    'M 0-3': 0
                }
            },
            option2: {
                speaking: {
                    '7.5-9.0': 6,
                    '7.0': 6,
                    '6.5': 3,
                    '6.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                },
                listening: {
                    '8.5-9.0': 6,
                    '8.0': 6,
                    '7.5': 3,
                    '6.0-7.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.5': 0,
                    '0-4.0': 0
                },
                reading: {
                    '8.0-9.0': 6,
                    '7.0-7.5': 6,
                    '6.5': 3,
                    '6.0': 3,
                    '5.0-5.5': 1,
                    '4.0-4.5': 1,
                    '3.5': 0,
                    '0-3.0': 0
                },
                writing: {
                    '7.5-9.0': 6,
                    '7.0': 6,
                    '6.5': 3,
                    '6.0': 3,
                    '5.5': 1,
                    '5.0': 1,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                }
            },
            option3: {
                speaking: {
                    '89+': 6,
                    '84-88': 6,
                    '76-83': 3,
                    '68-75': 3,
                    '59-67': 1,
                    '51-58': 1,
                    '42-50': 0,
                    '0-41': 0
                },
                listening: {
                    '89+': 6,
                    '82-88': 6,
                    '71-81': 3,
                    '60-70': 3,
                    '50-59': 1,
                    '39-49': 1,
                    '28-38': 0,
                    '0-27': 0
                },
                reading: {
                    '88+': 6,
                    '78-87': 6,
                    '69-77': 3,
                    '60-68': 3,
                    '51-59': 1,
                    '42-50': 1,
                    '33-41': 0,
                    '0-32': 0
                },
                writing: {
                    '90+': 6,
                    '88-89': 6,
                    '79-87': 3,
                    '69-78': 3,
                    '60-68': 1,
                    '51-59': 1,
                    '41-50': 0,
                    '0-40': 0
                }
            },
            option4: {
                speaking: {
                    '556-699': 6,
                    '518-555': 6,
                    '494-517': 3,
                    '456-493': 3,
                    '422-455': 1,
                    '387-421': 1,
                    '328-386': 0
                },
                listening: {
                    '546-699': 6,
                    '503-545': 6,
                    '462-502': 3,
                    '434-461': 3,
                    '393-433': 1,
                    '352-392': 1,
                    '306-351': 0
                },
                reading: {
                    '546-699': 6,
                    '503-545': 6,
                    '462-502': 3,
                    '434-461': 3,
                    '393-433': 1,
                    '352-392': 1,
                    '306-351': 0
                },
                writing: {
                    '558-699': 6,
                    '512-557': 6,
                    '472-511': 3,
                    '428-471': 3,
                    '379-427': 1,
                    '330-378': 1,
                    '268-329': 0
                }
            },
            option5: {
                speaking: {
                    '16-20': 6,
                    '14-15': 6,
                    '12-13': 3,
                    '10-11': 3,
                    '7-9': 1,
                    '6': 1,
                    '4-5': 0,
                    '0-3': 0
                },
                listening: {
                    '549-699': 6,
                    '523-548': 6,
                    '503-522': 3,
                    '458-502': 3,
                    '398-457': 1,
                    '369-397': 1,
                    '331-368': 0,
                    '0-330': 0
                },
                reading: {
                    '549-699': 6,
                    '524-548': 6,
                    '499-523': 3,
                    '453-498': 3,
                    '406-452': 1,
                    '375-405': 1,
                    '342-374': 0,
                    '0-341': 0
                },
                writing: {
                    '16-20': 6,
                    '14-15': 6,
                    '12-13': 3,
                    '10-11': 3,
                    '7-9': 1,
                    '6': 1,
                    '4-5': 0,
                    '0-3': 0
                }
            },
        },
        caWorkExperience: {
            option1: 0,
            option2: 40,
            option3: 53,
            option4: 64,
            option5: 72,
            option6: 80
        },
    };

    const sectionCpoints = {
        education: {
            low: {
                first: 0,
                second: 13,
                third: 25
            },
            high: {
                first: 0,
                second: 25,
                third: 50
            }
        },
        work: {
            low: {
                first: 0,
                second: 13,
                third: 25
            },
            high: {
                first: 0,
                second: 25,
                third: 50
            }
        },
        certificate: {
            low: 25,
            high: 50
        }
    };

    const sectionDpoints = {
        lawPartnerStatus: {
            option1: 0,
            option2: 15
        },
        NCLC: {
            zero : 0,
            lower: 25,
            higher: 50
        },
        postSecondary: {
            oneOrTwo: 15,
            threeOrMore: 30
        },
        employment: {
            NOC00: 200,
            other: 50
        },
        nomination: {
            option1: 0,
            option2: 600
        }
    } 
    
    //----------------------Section A Calculations--------------------------

    const calculateSectionA = () => {
        let newSpeakingPointSecond = 0;
        let newListeningPointSecond = 0;
        let newReadingPointSecond = 0;
        let newWritingPointSecond = 0;
    
        if (movingStatus === 'option2') {
            setAgePoint(prevAgePoint => prevAgePoint + sectionApointsSpouse.age[ageStatus]);
            setEducationPoint(prevEducationPoint => prevEducationPoint + sectionApointsSpouse.education[educationStatus]);
            setSpeakingPoint(prevSpeakingPoint => prevSpeakingPoint + sectionApointsSpouse.languageTest[languageTest].speaking[speakingTest]);
            setListeningPoint(prevListeningPoint => prevListeningPoint + sectionApointsSpouse.languageTest[languageTest].listening[listeningTest]);
            setReadingPoint(prevReadingPoint => prevReadingPoint + sectionApointsSpouse.languageTest[languageTest].reading[readingTest]);
            setWritingPoint(prevWritingPoint => prevWritingPoint + sectionApointsSpouse.languageTest[languageTest].writing[writingTest]);
            if (secondLanguageTest !== 'option6') {
                newSpeakingPointSecond = sectionApointsSpouse.languageTestSecond[secondLanguageTest].speaking[speakingTestSecond];
                newListeningPointSecond = sectionApointsSpouse.languageTestSecond[secondLanguageTest].listening[listeningTestSecond];
                newReadingPointSecond = sectionApointsSpouse.languageTestSecond[secondLanguageTest].reading[readingTestSecond];
                newWritingPointSecond = sectionApointsSpouse.languageTestSecond[secondLanguageTest].writing[writingTestSecond];
            }
            setCaWorkExperiencePoint(prevCaWorkExperiencePoint => prevCaWorkExperiencePoint + sectionApointsSpouse.caWorkExperience[workStatus]);
        } else if (movingStatus === 'option1' || movingStatus === ''){
            setAgePoint(prevAgePoint => prevAgePoint + sectionApointsWithoutSpouse.age[ageStatus]);
            setEducationPoint(prevEducationPoint => prevEducationPoint + sectionApointsWithoutSpouse.education[educationStatus]);
            setSpeakingPoint(prevSpeakingPoint => prevSpeakingPoint + sectionApointsWithoutSpouse.languageTest[languageTest].speaking[speakingTest]);
            setListeningPoint(prevListeningPoint => prevListeningPoint + sectionApointsWithoutSpouse.languageTest[languageTest].listening[listeningTest]);
            setReadingPoint(prevReadingPoint => prevReadingPoint + sectionApointsWithoutSpouse.languageTest[languageTest].reading[readingTest]);
            setWritingPoint(prevWritingPoint => prevWritingPoint + sectionApointsWithoutSpouse.languageTest[languageTest].writing[writingTest]);
            if (secondLanguageTest !== 'option6') {
                newSpeakingPointSecond = sectionApointsWithoutSpouse.languageTestSecond[secondLanguageTest].speaking[speakingTestSecond];
                newListeningPointSecond = sectionApointsWithoutSpouse.languageTestSecond[secondLanguageTest].listening[listeningTestSecond];
                newReadingPointSecond = sectionApointsWithoutSpouse.languageTestSecond[secondLanguageTest].reading[readingTestSecond];
                newWritingPointSecond = sectionApointsWithoutSpouse.languageTestSecond[secondLanguageTest].writing[writingTestSecond];
            }
            setCaWorkExperiencePoint(prevCaWorkExperiencePoint => prevCaWorkExperiencePoint + sectionApointsWithoutSpouse.caWorkExperience[workStatus]);
        }
    
        // Update total second language points only once after calculations
        const totalPoints = newSpeakingPointSecond + newListeningPointSecond + newReadingPointSecond + newWritingPointSecond;
        setTotalSecondLanguage(totalPoints);
    };
    
    //----------------------------------------------------------------------
    //----------------------Section B Calculations--------------------------

    const [sectionB, setSectionB] = useState(0);

    const calculateSectionB = () => {
        if (movingStatus === 'option2') {
            setSpouseEducationPoint(prevSpouseEducationPoint => prevSpouseEducationPoint + sectionApointsSpouse.spouseEducation[spouseEducationStatus]);
            if (spouseLanguageTest !== 'option6') {
                setSpeakingPointSpouse(prevSpeakingPointSpouse => prevSpeakingPointSpouse + sectionApointsSpouse.languageTestSpouse[spouseLanguageTest].speaking[speakingTestSpouse]);
                setListeningPointSpouse(prevListeningPointSpouse => prevListeningPointSpouse + sectionApointsSpouse.languageTestSpouse[spouseLanguageTest].listening[listeningTestSpouse]);
                setReadingPointSpouse(prevReadingPointSpouse => prevReadingPointSpouse + sectionApointsSpouse.languageTestSpouse[spouseLanguageTest].reading[readingTestSpouse]);
                setWritingPointSpouse(prevWritingPointSpouse => prevWritingPointSpouse + sectionApointsSpouse.languageTestSpouse[spouseLanguageTest].writing[writingTestSpouse]);
            }
            setSpouseWorkStatusPoint(prevSpouseWorkStatusPoint => prevSpouseWorkStatusPoint + sectionApointsSpouse.spouseWorkStatus[spouseWorkStatus]);
        }
    }

 
    useEffect(() => {
        setTotalFirstLanguage(speakingPoint + listeningPoint + writingPoint + readingPoint);
    }, [speakingPoint, listeningPoint, writingPoint, readingPoint]);

    useEffect(() => {
        if (movingStatus === 'option2'){
            if (totalSecondLanguage > 22) {
                setTotalSecondLanguage(22);
            }
        }
    }, [movingStatus, totalSecondLanguage]);

    useEffect(() => {
        setTotalSpouseFirstLanguage(speakingPointSpouse + listeningPointSpouse + writingPointSpouse + readingPointSpouse);
    }, [speakingPointSpouse, listeningPointSpouse, writingPointSpouse, readingPointSpouse])

    
    useEffect(() => {
        setSectionA(agePoint + educationPoint + speakingPoint + listeningPoint + readingPoint + writingPoint + totalSecondLanguage + caWorkExperiencePoint);
    }, [agePoint, educationPoint, speakingPoint, listeningPoint, readingPoint, writingPoint, totalSecondLanguage, caWorkExperiencePoint]);

    useEffect(() => {
        setSectionB(spouseEducationPoint + speakingPointSpouse + listeningPointSpouse + readingPointSpouse + writingPointSpouse + spouseWorkStatusPoint);
    }, [spouseEducationPoint, speakingPointSpouse, listeningPointSpouse, readingPointSpouse, writingPointSpouse, spouseWorkStatusPoint]);


    //----------------------------------------------------------------------
    //----------------------Section C Calculations--------------------------


    //----------------------Section C Part 1 Calculations--------------------------

    const [sectionC, setSectionC] = useState(0);
    const [sectionCPart1, setSectionCPart1] = useState(0);

    const [firstPart1SectionC, setFirstPart1SectionC] = useState(0);
    const [secondPart1SectionC, setSecondPart1SectionC] = useState(0);

    const [clbCheckFor7, setClbCheckFor7] = useState(false);
    const [clbCheckFor9, setClbCheckFor9] = useState(false);

    const speakingScore = sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest];
    const listeningScore = sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest];
    const readingScore = sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest];
    const writingScore = sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest];

    const isWithinRange = (score) => score >= 16 && score < 29;

    useEffect(() => {
        if (
            sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
            sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29 &&
            sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29 &&
            sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29
        ) {
            setClbCheckFor7(false);
            setClbCheckFor9(true);
        } else if (
            (isWithinRange(speakingScore) && 
             sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (isWithinRange(speakingScore) && 
             isWithinRange(listeningScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (isWithinRange(speakingScore) &&
             isWithinRange(writingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29) ||
            (isWithinRange(speakingScore) &&
            isWithinRange(readingScore) &&
              sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29 &&
              sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (isWithinRange(listeningScore) &&
             isWithinRange(writingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29) ||
            (isWithinRange(listeningScore) &&
             isWithinRange(readingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (isWithinRange(readingScore) &&
             isWithinRange(writingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29) ||
            (
             isWithinRange(speakingScore) && 
             isWithinRange(listeningScore) &&
             isWithinRange(readingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (
             isWithinRange(speakingScore) && 
             isWithinRange(listeningScore) &&
             isWithinRange(writingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29) ||
            (
             isWithinRange(speakingScore) && 
             isWithinRange(readingScore) &&
             isWithinRange(writingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29) ||
            (
             isWithinRange(listeningScore) && 
             isWithinRange(readingScore) &&
             isWithinRange(writingScore) &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29) ||
            (isWithinRange(listeningScore) && 
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (isWithinRange(readingScore) && 
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.writing?.[writingTest] >= 29) ||
            (isWithinRange(writingScore) && 
             sectionApointsSpouse?.languageTest?.[languageTest]?.speaking?.[speakingTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.listening?.[listeningTest] >= 29 &&
             sectionApointsSpouse?.languageTest?.[languageTest]?.reading?.[readingTest] >= 29) ||
            (isWithinRange(speakingScore) &&
             isWithinRange(listeningScore) &&
             isWithinRange(readingScore) &&
             isWithinRange(writingScore))
            ) {
            setClbCheckFor7(true);
            setClbCheckFor9(false);
        } else {
            setClbCheckFor7(false);
            setClbCheckFor9(false);
        }
        
        checkForEducation();
    }, [languageTest, speakingTest, listeningTest, readingTest, writingTest, sectionApointsSpouse]);
    
    

    const [educationLess, setEducationLess] = useState(false);
    const [educationSecondary, setEducationSecondary] = useState(false);
    const [educationPostSecondary, setEducationPostSecondary] = useState(false);


    const checkForEducation = () => {
        if (educationStatus === 'option3' || educationStatus === 'option4' || educationStatus === 'option5') {
            setEducationPostSecondary(false);
            setEducationSecondary(true);
            //console.log("Post Secondary School");
        } else if (educationStatus === 'option6' || educationStatus === 'option7' || educationStatus === 'option8') {
            setEducationPostSecondary(true);
            setEducationSecondary(false);
            //alert("Two or More Post Secondary School");
        } else if (educationStatus === 'option1' || educationStatus === 'option2'){
            setEducationLess(true);
            setEducationSecondary(false);
            setEducationPostSecondary(false);
            //alert("Secondary School");
        }
    }

    
    const calculateFirstPart1SectionC = () => {
        if (clbCheckFor9 === true) {
            if (educationSecondary === true) {
                setFirstPart1SectionC(prevFirstPart1SectionC => prevFirstPart1SectionC + sectionCpoints.education.high.second);
            } else if (educationPostSecondary === true) {
                setFirstPart1SectionC(prevFirstPart1SectionC => prevFirstPart1SectionC + sectionCpoints.education.high.third);
            } else if (educationLess === true) {
                setFirstPart1SectionC(prevFirstPart1SectionC => prevFirstPart1SectionC + sectionCpoints.education.low.first);
            }
        } 
        
        if (clbCheckFor7 === true) {
            if (educationSecondary === true) {
                //console.log("13");
                setFirstPart1SectionC(prevFirstPart1SectionC => prevFirstPart1SectionC + sectionCpoints.education.low.second);
            } else if (educationPostSecondary === true) {
                setFirstPart1SectionC(prevFirstPart1SectionC => prevFirstPart1SectionC + sectionCpoints.education.low.third);
            } else if (educationLess === true) {
                setFirstPart1SectionC(prevFirstPart1SectionC => prevFirstPart1SectionC + sectionCpoints.education.low.first);
            }
        }
    }

    const calculateSecondPart1SectionC = () => {
        if (educationSecondary === true) {
            if (workStatus === 'option2') {
                setSecondPart1SectionC(prevSecondPart1SectionC => prevSecondPart1SectionC + sectionCpoints.work.low.second);
            } else if (workStatus === 'option3' || workStatus === 'option4' || workStatus === 'option5' || workStatus === 'option6') {
                setSecondPart1SectionC(prevSecondPart1SectionC => prevSecondPart1SectionC + sectionCpoints.work.low.third);
            }
        } else if (educationPostSecondary === true) {
            if (workStatus === 'option2') {
                setSecondPart1SectionC(prevSecondPart1SectionC => prevSecondPart1SectionC + sectionCpoints.work.high.second);
            } else if (workStatus === 'option3' || workStatus === 'option4' || workStatus === 'option5' || workStatus === 'option6') {
                setSecondPart1SectionC(prevSecondPart1SectionC => prevSecondPart1SectionC + sectionCpoints.work.high.third);
            }
        }
    };

    useEffect(() => {
        const totalPart1 = firstPart1SectionC + secondPart1SectionC;
        const limitedValue = Math.min(totalPart1, 50);

        setSectionCPart1(limitedValue);
    }, [firstPart1SectionC, secondPart1SectionC]);

    //-----------------------------------------------------------------------------
    //----------------------Section C Part 2 Calculations--------------------------

    const [sectionCPart2, setSectionCPart2] = useState(0);

    const [firstPart2SectionC, setFirstPart2SectionC] = useState(0);
    const [secondPart2SectionC, setSecondPart2SectionC] = useState(0);
    const [certificateSectionC, setCertificateSectionC] = useState(0);

    const calculateFirstPart2SectionC = () => {
        if (clbCheckFor9 === true) {
            if (foreignWorkStatus === 'option4') {
                setFirstPart2SectionC(prevFirstPart2SectionC => prevFirstPart2SectionC + sectionCpoints.work.high.third);
            } else if (foreignWorkStatus === 'option2' || foreignWorkStatus === 'option3') {
                setFirstPart2SectionC(prevFirstPart2SectionC => prevFirstPart2SectionC + sectionCpoints.work.high.second);
            } else if (foreignWorkStatus === 'option1') {
                setFirstPart2SectionC(prevFirstPart2SectionC => prevFirstPart2SectionC + sectionCpoints.work.high.first);
            }
        } else if (clbCheckFor7 === true) {
            if (foreignWorkStatus === 'option4') {
                setFirstPart2SectionC(prevFirstPart2SectionC => prevFirstPart2SectionC + sectionCpoints.work.low.third);
            } else if (foreignWorkStatus === 'option2' || foreignWorkStatus === 'option3') {
                setFirstPart2SectionC(prevFirstPart2SectionC => prevFirstPart2SectionC + sectionCpoints.work.low.second);
            } else if (foreignWorkStatus === 'option1') {
                setFirstPart2SectionC(prevFirstPart2SectionC => prevFirstPart2SectionC + sectionCpoints.work.low.first);
            }
        }
    };
    

    const [clbCheckFor5, setClbCheckFor5] = useState(false);
    const [clbCheckFor5Speaking, setClbCheckFor5Speaking] = useState(false);
    const [clbCheckFor5Listening, setClbCheckFor5Listening] = useState(false);
    const [clbCheckFor5Reading, setClbCheckFor5Reading] = useState(false);
    const [clbCheckFor5Writing, setClbCheckFor5Writing] = useState(false);

    const [clbCheckFor7Speaking, setClbCheckFor7Speaking] = useState(false);
    const [clbCheckFor7Listening, setClbCheckFor7Listening] = useState(false);
    const [clbCheckFor7Reading, setClbCheckFor7Reading] = useState(false);
    const [clbCheckFor7Writing, setClbCheckFor7Writing] = useState(false);

    const [clbCheckFor57, setClbCheckFor57] = useState(false);

      const speakingCLB5Array = [
        '5',
        '6',
        '5.0',
        '5.5',
        '51-58',
        '59-67',
        '387-421',
        '422-455',
        '6',
        '7-9'
      ];

      const speakingCLB7Array = [
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0',
        '7.5-9.0',
        '68-75',
        '76-83',
        '84-88',
        '89+',
        '456-493',
        '494-517',
        '518-555',
        '556-699',
        '10-11',
        '12-13',
        '14-15',
        '16-20'
      ];
      
      const listeningCLB5Array = [
        '5',
        '6',
        '5.0',
        '5.5',
        '39-48',
        '50-59',
        '352-392',
        '393-433',
        '369-397',
        '398-457'
      ];

      const listeningCLB7Array = [
        '7',
        '8',
        '9',
        '10-12',
        '6.0-7.0',
        '7.5',
        '8.0',
        '8.5-9.0',
        '60-70',
        '71-81',
        '82-88',
        '89+',
        '434-461',
        '462-502',
        '503-545',
        '546-699',
        '458-502',
        '503-522',
        '523-548',
        '549-699'
      ];

      const readingCLB5Array = [
        '5',
        '6',
        '4.0-4.5',
        '5.0-5.5',
        '42-50',
        '51-59',
        '352-392',
        '393-433',
        '375-405',
        '406-452'
      ];

      const readingCLB7Array = [
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0-7.5',
        '8.0-9.0',
        '60-68',
        '69-77',
        '78-87',
        '88+',
        '434-461',
        '462-502',
        '503-545',
        '546-699',
        '453-498',
        '499-523',
        '524-548',
        '549-699'
      ];

      const writingCLB5Array = [
        '5',
        '6',
        '5.0',
        '5.5',
        '51-59',
        '60-68',
        '330-378',
        '379-427',
        '6',
        '7-9'
      ]

      const writingCLB7Array = [
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0',
        '7.5-9.0',
        '69-78',
        '79-87',
        '88-89',
        '90+',
        '428-471',
        '472-511',
        '512-557',
        '558-699',
        '10-11',
        '12-13',
        '14-15',
        '16-20'
      ]
    
      useEffect(() => {
        if (speakingCLB5Array.includes(speakingTest)) {
            setClbCheckFor5Speaking(true);
            setClbCheckFor7Speaking(false);
        } else if (speakingCLB7Array.includes(speakingTest)) {
            setClbCheckFor5Speaking(false);
            setClbCheckFor7Speaking(true);
        }

        if (listeningCLB5Array.includes(listeningTest)){
            setClbCheckFor5Listening(true);
            setClbCheckFor7Listening(false);
        } else if (listeningCLB7Array.includes(listeningTest)){
            setClbCheckFor5Listening(false);
            setClbCheckFor7Listening(true);
        }

        if(readingCLB5Array.includes(readingTest)){
            setClbCheckFor5Reading(true);
            setClbCheckFor7Reading(false);
        } else if (readingCLB7Array.includes(readingTest)){
            setClbCheckFor5Reading(false);
            setClbCheckFor7Reading(true);
        }

        if(writingCLB5Array.includes(writingTest)){
            setClbCheckFor5Writing(true);
            setClbCheckFor7Writing(false);
        } else if (writingCLB7Array.includes(writingTest)){
            setClbCheckFor5Writing(false);
            setClbCheckFor7Writing(true);
        }
      }, [speakingTest, listeningTest, readingTest, writingTest, speakingCLB5Array, speakingCLB7Array, listeningCLB5Array, listeningCLB7Array, readingCLB5Array, readingCLB7Array, writingCLB5Array, writingCLB7Array]);

      useEffect(() => {
        if(clbCheckFor5Speaking === true && clbCheckFor5Listening === true && clbCheckFor5Reading === true && clbCheckFor5Writing === true) {
            setClbCheckFor5(true);
            setClbCheckFor7(false);
        } else if (clbCheckFor7Speaking === true && clbCheckFor7Listening === true && clbCheckFor7Reading === true && clbCheckFor7Writing === true) {
            setClbCheckFor5(false);
            setClbCheckFor7(true);
        } else if (clbCheckFor5Speaking === true  || clbCheckFor5Listening === true  || clbCheckFor5Reading === true  || clbCheckFor5Writing === true ||clbCheckFor7Speaking === true  || clbCheckFor7Listening === true  || clbCheckFor7Reading === true  || clbCheckFor7Writing === true) {
            setClbCheckFor57(true);
            setClbCheckFor5(false);
            setClbCheckFor7(false);
        }
      }, [clbCheckFor5Speaking, clbCheckFor7Speaking, clbCheckFor5Listening, clbCheckFor7Listening, clbCheckFor5Reading, clbCheckFor7Reading, clbCheckFor5Writing, clbCheckFor7Writing]);

    const calculateSecondPart2SectionC = () => {
        if (foreignWorkStatus === 'option2' || foreignWorkStatus === 'option3') {
            if (workStatus === 'option2'){
                setSecondPart2SectionC(prevSecondPart2SectionC => prevSecondPart2SectionC + sectionCpoints.work.low.second);
            } else if (workStatus === 'option3' || workStatus === 'option4' || workStatus === 'option5' || workStatus === 'option6'){
                setSecondPart2SectionC(prevSecondPart2SectionC => prevSecondPart2SectionC + sectionCpoints.work.high.second);
            } else if (workStatus === 'option1') {
                setSecondPart2SectionC(prevSecondPart2SectionC => prevSecondPart2SectionC + sectionCpoints.work.low.first);
            }
        } else if (foreignWorkStatus === 'option4') {
            if (workStatus === 'option2'){
                setSecondPart2SectionC(prevSecondPart2SectionC => prevSecondPart2SectionC + sectionCpoints.work.high.second);
            } else if (workStatus === 'option3' || workStatus === 'option4' || workStatus === 'option5' || workStatus === 'option6'){
                setSecondPart2SectionC(prevSecondPart2SectionC => prevSecondPart2SectionC + sectionCpoints.work.high.third);
            } else if (workStatus === 'option1') {
                setSecondPart2SectionC(prevSecondPart2SectionC => prevSecondPart2SectionC + sectionCpoints.work.low.first);
            }
        }
    };

    const calculateCertificateSectionC = () => {
        if ((certificateStatus === 'option2') && (clbCheckFor57 === true)){
            setCertificateSectionC(prevCertificateSectionC => prevCertificateSectionC + sectionCpoints.certificate.low);
        } else if ((certificateStatus === 'option2') && (clbCheckFor7 === true)){
            setCertificateSectionC(prevCertificateSectionC => prevCertificateSectionC + sectionCpoints.certificate.high * 2);
        }

    }

    useEffect(() => {
        const totalPart2 = firstPart2SectionC + secondPart2SectionC;
        const limitedValue = Math.min(totalPart2, 50);

        setSectionCPart2(limitedValue);
    }, [firstPart2SectionC, secondPart2SectionC]);


    useEffect(() => {
        const totalPart3 = sectionCPart1 + sectionCPart2 + certificateSectionC;
        const limitedValue = Math.min(totalPart3, 100);

        setSectionC(limitedValue);
    }, [sectionCPart1, sectionCPart2, certificateSectionC]);

    const calculateSectionC = () => {
        calculateFirstPart1SectionC();
        calculateSecondPart1SectionC();
        calculateFirstPart2SectionC();
        calculateSecondPart2SectionC();
        //calculateCertificateSectionC();

        limitSectionC();
    };

    

    //----------------------------------------------------------------------
    //----------------------Section D Calculations--------------------------
    
    const [sectionD, setSectionD] = useState(0);

    const [lawPartnerPoint, setLawPartnerPoint] = useState(0);
    const [NCLCPoint, setNCLCPoint] = useState(0);
    const [canadaPostSecondaryPoint, setCanadaPostSecondaryPoint] = useState(0);
    const [employmentPoint, setEmploymentPoint] = useState(0);
    const [nominationPoint, setNominationPoint] = useState(0);

    const checkerNCLCPoint = () => {
        if (languageTest === 'option4' || languageTest === 'option5') {
            if ((speakingCLB7Array.includes(speakingTest)) && 
                (listeningCLB7Array.includes(listeningTest)) &&
                (readingCLB7Array.includes(readingTest)) &&
                (writingCLB7Array.includes(writingTest))
            ) {
                if (secondLanguageTest === 'option6') {
                    setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.zero);
                } else if (secondLanguageTest === 'option1' || secondLanguageTest === 'option2' || secondLanguageTest === 'option3') {
                    if (
                        (
                          ((speakingCLB5Array.includes(speakingTestSecond) || speakingCLB7Array.includes(speakingTestSecond))) &&
                          ((listeningCLB5Array.includes(listeningTestSecond) || listeningCLB7Array.includes(listeningTestSecond))) &&
                          ((readingCLB5Array.includes(readingTestSecond) || readingCLB7Array.includes(readingTestSecond))) &&
                          ((writingCLB5Array.includes(writingTestSecond) || writingCLB7Array.includes(writingTestSecond)))
                        )
                      ) {
                        setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.higher);
                    } else {
                        setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.lower);
                    }    
                }
            } 
        } 
        
        if (languageTest === 'option1' || languageTest === 'option2' || languageTest === 'option3') {
            if (
                (
                  ((speakingCLB5Array.includes(speakingTest) || speakingCLB7Array.includes(speakingTest))) &&
                  ((listeningCLB5Array.includes(listeningTest) || listeningCLB7Array.includes(listeningTest))) &&
                  ((readingCLB5Array.includes(readingTest) || readingCLB7Array.includes(readingTest))) &&
                  ((writingCLB5Array.includes(writingTest) || writingCLB7Array.includes(writingTest)))
                )
              ) {
                if (secondLanguageTest === 'option6') {
                    setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.zero);
                } else if (secondLanguageTest === 'option4' || secondLanguageTest === 'option5') {
                    if (
                        (
                          ((speakingCLB7Array.includes(speakingTestSecond))) &&
                          ((listeningCLB7Array.includes(listeningTestSecond))) &&
                          ((readingCLB7Array.includes(readingTestSecond))) &&
                          ((writingCLB7Array.includes(writingTestSecond)))
                        )
                      ) {
                        setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.higher);
                      }   
                }
            } else {
                if (secondLanguageTest === 'option6') {
                    setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.zero);
                } else if (secondLanguageTest === 'option4' || secondLanguageTest === 'option5') {
                    if (
                        (
                          ((speakingCLB7Array.includes(speakingTestSecond))) &&
                          ((listeningCLB7Array.includes(listeningTestSecond))) &&
                          ((readingCLB7Array.includes(readingTestSecond))) &&
                          ((writingCLB7Array.includes(writingTestSecond)))
                        )
                      ) {
                        setNCLCPoint(prevNCLCPoint => prevNCLCPoint + sectionDpoints.NCLC.lower);
                      }   
                }
            }


        }
        
    }


    const calculateSectionD = () => {
        checkerNCLCPoint();
        calculateCertificateSectionC();

        if(lawPartnerStatus === 'option2') {
            setLawPartnerPoint(prevLawPartnerPoint => prevLawPartnerPoint + sectionDpoints.lawPartnerStatus.option2);
        }

        if(caEducationStatus === 'option2') {
            if(caEducationLevelStatus === 'option1'){
                setCanadaPostSecondaryPoint(prevCanadaPostSecondaryPoint => prevCanadaPostSecondaryPoint + sectionDpoints.postSecondary.oneOrTwo);
            } else {
                setCanadaPostSecondaryPoint(prevCanadaPostSecondaryPoint => prevCanadaPostSecondaryPoint + sectionDpoints.postSecondary.threeOrMore);
            }
        } 

        if(nocStatus === 'option1') {
            setEmploymentPoint(prevEmploymentPoint => prevEmploymentPoint + sectionDpoints.employment.NOC00);
        } else if (nocStatus === '') {
            setEmploymentPoint(prevEmploymentPoint => prevEmploymentPoint + 0);
        } else {
            setEmploymentPoint(prevEmploymentPoint => prevEmploymentPoint + sectionDpoints.employment.other);
        }

        if(nominationStatus === 'option2'){
            setNominationPoint(prevNomationPoint => prevNomationPoint + sectionDpoints.nomination.option2);
        }
    }


    //------------------------------------------------------------------------------------
    //----------------------- Section Point Limitations ----------------------------------

    const limitSectionC = () => {
        if(sectionCPart1 > 50) {
            setSectionCPart1(50);
        }

        if(sectionCPart2 > 50) {
            setSectionCPart2(50);
        }

        if(sectionC > 100) {
            setSectionC(100);
        }
    }


    useEffect(() => {
        setSectionD(lawPartnerPoint + NCLCPoint + canadaPostSecondaryPoint + employmentPoint + nominationPoint);
    }, [lawPartnerPoint, NCLCPoint, canadaPostSecondaryPoint, employmentPoint, nominationPoint]);

    useEffect(() => {
        if (sectionD > 600) {
            setSectionD(600);
        }
    }, [sectionD]);

  

    const resetVariables = () => {
        setFirstPart1SectionC(0);
        setSecondPart1SectionC(0);
        setFirstPart2SectionC(0);
        setSecondPart2SectionC(0);
        setCertificateSectionC(0);

        setAgePoint(0);
        setEducationPoint(0);

        setSpeakingPoint(0);
        setListeningPoint(0);
        setReadingPoint(0);
        setWritingPoint(0);

        setSpeakingPointSecond(0);
        setListeningPointSecond(0);
        setReadingPointSecond(0);
        setWritingPointSecond(0);

        setCaWorkExperiencePoint(0);
        setSpouseEducationPoint(0);

        setSpeakingPointSpouse(0);
        setListeningPointSpouse(0);
        setReadingPointSpouse(0);
        setWritingPointSpouse(0);

        setSpouseWorkStatusPoint(0);

        setEducationSecondary(0);
        setEducationPostSecondary(0);

        setLawPartnerPoint(0);
        setNCLCPoint(0);
        setCanadaPostSecondaryPoint(0);
        setEmploymentPoint(0);
        setNominationPoint(0);
      };

    const [clickedOnce, setClickedOnce] = useState(false);



    const calculateScore = () => {
        if (clickedOnce) {
            resetVariables();
        }
        setShowResults(true);
        calculateSectionA();
        calculateSectionB();
        calculateSectionC();
        calculateSectionD();
        setClickedOnce(true);
    };


    //--------------------------- Total Point Calculation -----------------------------------------

    const [totalPointSections, setTotalPointSections] = useState(0);

    useEffect(() => {
        setTotalPointSections(sectionA + sectionB + sectionC + sectionD);
    }, [sectionA, sectionB, sectionC, sectionD]);
    

    const today = new Date();

    // Extract the year, month, and day from the Date object
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Note: Month is zero-indexed, so add 1
    const day = today.getDate();
  
    // Format the date as DD-MM-YYYY
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    
    useEffect(() => {
        // Reset the values when languageTest changes
        setShowResults(false); // Reset showResults to false when languageTest changes
    }, [languageTest]);

    const [isButtonVisible, setIsButtonVisible] = useState(false);


    useEffect(() => {
        const buttonChecker = () => {
            if (maritalOption === 'option2' || maritalOption === 'option3') {
                if (partnerStatus === 'option2') {
                    if (additionalStatus === 'option2') {
                        if (nocStatus !== '' && lawPartnerStatus !== '') {
                            return true;
                        }
                    }
                    else if (additionalStatus === 'option1') {
                        if (lawPartnerStatus !== '') {
                            return true;
                        }
                    }   
                } else if (partnerStatus === 'option1') {
                        if (movingStatus === 'option1') {
                            if (lawPartnerStatus !== '') {
                                return true;
                            }
                        }
                        else if (movingStatus === 'option2') {
                            if (spouseLanguageTest === 'option6') {
                                return true;
                            } else {
                                if (speakingTestSpouse && listeningTestSpouse && readingTestSpouse && writingTestSpouse) {
                                    return true; 
                                }
                            }
                        }   
                }
            } else {
                if (additionalStatus === 'option2') {
                    if (nocStatus !== '' && lawPartnerStatus !== '') {
                        return true;
                    }
                }
                else if (additionalStatus === 'option1') {
                    if (lawPartnerStatus !== '') {
                        return true;
                    }
                }
            }
            
            return false;
        };
    
        const visibility = buttonChecker();
        setIsButtonVisible(visibility);
    }, [maritalOption, partnerStatus, movingStatus, additionalStatus, nocStatus, lawPartnerStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse]);
    
    useEffect(() => {
        if(showResults === true) {
            setShowResults (false);
        }
    }, [maritalOption, partnerStatus, movingStatus, ageStatus, educationStatus, caEducationStatus, caEducationLevelStatus, languageTest, speakingTest, listeningTest, readingTest, writingTest, secondLanguageTest, speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, workStatus, foreignWorkStatus, certificateStatus, additionalStatus, nocStatus, nominationStatus, lawPartnerStatus, spouseEducationStatus, spouseWorkStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse]);



    const [maxAgePoint, setMaxAgePoint] = useState(0);
    const [maxEducationPoint, setMaxEducationPoint] = useState(0);
    const [maxFirstLanguagePoint, setMaxFirstLanguagePoint] = useState(0);
    const [maxSecondLanguagePoint, setMaxSecondLanguagePoint] = useState(0);
    const [maxWorkExperiencePoint, setMaxWorkExperiencePoint] = useState(0);

    useEffect(() => {
        if(movingStatus === 'option2') {
            setMaxAgePoint(100);
            setMaxEducationPoint(140);
            setMaxFirstLanguagePoint(128);
            setMaxSecondLanguagePoint(22);
            setMaxWorkExperiencePoint(70);
        } else {
            setMaxAgePoint(110);
            setMaxEducationPoint(150);
            setMaxFirstLanguagePoint(136);
            setMaxSecondLanguagePoint(24);
            setMaxWorkExperiencePoint(80);
        }
    }, [movingStatus])

    return (
        <div>
            {isButtonVisible && (
                <button className='bg-[#FF0000] text-white' onClick={calculateScore}>{t("calculate")}</button>
            )}

            <animated.div style={popUpAnimation}>
                {showResults && (
                    <div className='scoreContainer'>
                        <p className='totalScore'>{t("totalScore")}: {totalPointSections}</p> {/* Display the calculated score */}
                        <button className="detailsButton" onClick={toggleDetails}>
                            {showDetails ? t("detailHide") : t("detailShow")}
                        </button>
                    </div>
                )}
            </animated.div>

            <animated.div style={popUpAnimation}>
            {showResults && (
                <div className='callToAction'>
                <p>{t("callToAction")}</p>
                <button onClick={() => window.open("https://www.northernpathways.ca/pre-assessment-form", "_blank")} className="button-with-arrow">
                    <AiOutlineArrowRight size={36}/> {t("preAssessment")}
                </button>
                </div>
            )}
            </animated.div>
            

            {/* Overlay container for detailed breakdown */}
            {showDetails && (
            <div>
                <div className="overlay">
                    <div>
                        <button className="closeButton" onClick={toggleDetails}>
                            <FaTimes />
                        </button>                    
                    </div>
                    <div className="modal">
                        <div className="sectionContainer">
                            <div id="tableSectionA">
                                <h3>{t("sectionA")}</h3>
                                <table>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("age")}</td>
                                        <td>{agePoint} / {maxAgePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("education")}</td>
                                        <td>{educationPoint} / {maxEducationPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("caWork")}</td>
                                        <td>{caWorkExperiencePoint} / {maxWorkExperiencePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("firstLanguageTotal")}</td>
                                        <td>{totalFirstLanguage} / {maxFirstLanguagePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("secondLanguageTotal")}</td>
                                        <td>{totalSecondLanguage} / {maxSecondLanguagePoint}</td>
                                    </tr>
                                    <tr className='total-row'>
                                        <td>{t("sectionAtotal")}</td>
                                        <td>{sectionA}</td>
                                    </tr>
                                </table>

                                <table>
                                    <h4>{t("firstLanguage")}</h4>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("speaking")}</td>
                                        <td>{speakingPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("listening")}</td>
                                        <td>{listeningPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("reading")}</td>
                                        <td>{readingPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("writing")}</td>
                                        <td>{writingPoint}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#fcd4d4'}}>
                                        <td>{t("firstLanguageTotal")}</td>
                                        <td>{totalFirstLanguage}</td>
                                    </tr>
                                </table>

                                <table>
                                    <h4>{t("secondLanguage")}</h4>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("speaking")}</td>
                                        <td>{speakingPointSecond}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("listening")}</td>
                                        <td>{listeningPointSecond}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("reading")}</td>
                                        <td>{readingPointSecond}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("writing")}</td>
                                        <td>{writingPointSecond}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#fcd4d4'}}>
                                        <td>{t("secondLanguageTotal")}</td>
                                        <td>{totalSecondLanguage}</td>
                                    </tr>
                                </table>
                            </div>

                            <div id="tableSectionB">
                                <table>
                                    <h3>{t("sectionB")}</h3>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("spouseEducation")}</td>
                                        <td>{spouseEducationPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("spouseWorkExperience")}</td>
                                        <td>{spouseWorkStatusPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("spouseLanguageTotal")}</td>
                                        <td>{totalSpouseFirstLanguage}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#ffacac'}}>
                                        <td>{t("sectionBtotal")}</td>
                                        <td>{sectionB}</td>
                                    </tr>
                                </table>

                                <table>
                                    <h4>{t("spouseLanguage")}</h4>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("speaking")}</td>
                                        <td>{speakingPointSpouse}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("listening")}</td>
                                        <td>{listeningPointSpouse}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("reading")}</td>
                                        <td>{readingPointSpouse}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("writing")}</td>
                                        <td>{writingPointSpouse}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#fcd4d4'}}>
                                        <td>{t("spouseLanguageTotal")}</td>
                                        <td>{totalSpouseFirstLanguage}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className="sectionContainer">
                            <div id="tableSectionC">
                                <table>
                                    <h3>{t("sectionC")}</h3>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("educationAndLanguage")}</td>
                                        <td>{firstPart1SectionC}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("workAndEducation")}</td>
                                        <td>{secondPart1SectionC}</td>
                                    </tr>
                                    <tr className='subtotal'>
                                        <td>{t("sectionCpart1")}</td>
                                        <td>{sectionCPart1}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("foreignWorkAndLanguage")}</td>
                                        <td>{firstPart2SectionC}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("canadianAndForeign")}</td>
                                        <td>{secondPart2SectionC}</td>
                                    </tr>
                                    <tr className='subtotal' style={{backgroundColor: '#fcd4d4'}}>
                                        <td>{t("sectionCpart2")}</td>
                                        <td>{sectionCPart2}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("qualificationCertificate")}</td>
                                        <td>{certificateSectionC}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#ffacac'}}>
                                        <td>{t("sectionCtotal")}</td>
                                        <td>{sectionC}</td>
                                    </tr>
                                </table>
                            </div>

                            <div id="tableSectionD">
                                <table>
                                    <h3>{t("sectionD")}</h3>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("sibling")}</td>
                                        <td>{lawPartnerPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("frenchProf")}</td>
                                        <td>{NCLCPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("postSecondary")}</td>
                                        <td>{canadaPostSecondaryPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("arrangedEmployment")}</td>
                                        <td>{employmentPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("nomination")}</td>
                                        <td>{nominationPoint}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#ffacac'}}>
                                        <td>{t("sectionDtotal")}</td>
                                        <td>{sectionD}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <p>{t("totalScore")}: {totalPointSections}</p>
                    </div>

                    <div className="download-icon-container">
                        <PDFDownloadLink document={
                            <DetailsPDFDocument
                                agePoint={agePoint}
                                educationPoint={educationPoint}
                                caWorkExperiencePoint={caWorkExperiencePoint}
                                sectionA={sectionA}
                                speakingPoint={speakingPoint}
                                listeningPoint={listeningPoint}
                                readingPoint={readingPoint}
                                writingPoint={writingPoint}
                                totalFirstLanguage={totalFirstLanguage}
                                speakingPointSecond={speakingPointSecond}
                                listeningPointSecond={listeningPointSecond}
                                readingPointSecond={readingPointSecond}
                                writingPointSecond={writingPointSecond}
                                totalSecondLanguage={totalSecondLanguage}
                                spouseEducationPoint={spouseEducationPoint}
                                spouseWorkStatusPoint={spouseWorkStatusPoint}
                                sectionB={sectionB}
                                speakingPointSpouse={speakingPointSpouse}
                                listeningPointSpouse={listeningPointSpouse}
                                readingPointSpouse={readingPointSpouse}
                                writingPointSpouse={writingPointSpouse}
                                totalSpouseFirstLanguage={totalSpouseFirstLanguage}
                                firstPart1SectionC={firstPart1SectionC}
                                secondPart1SectionC={secondPart1SectionC}
                                sectionCPart1={sectionCPart1}
                                firstPart2SectionC={firstPart2SectionC}
                                secondPart2SectionC={secondPart2SectionC}
                                sectionCPart2={sectionCPart2}
                                certificateSectionC={certificateSectionC}
                                sectionC={sectionC}
                                lawPartnerPoint={lawPartnerPoint}
                                NCLCPoint={NCLCPoint}
                                canadaPostSecondaryPoint={canadaPostSecondaryPoint}
                                employmentPoint={employmentPoint}
                                nominationPoint={nominationPoint}
                                sectionD={sectionD}
                                totalPointSections={totalPointSections}
                                date={formattedDate}
                                maxAgePoint={maxAgePoint}
                                maxEducationPoint={maxEducationPoint}
                                maxFirstLanguagePoint={maxFirstLanguagePoint}
                                maxSecondLanguagePoint={maxSecondLanguagePoint}
                                maxWorkExperiencePoint={maxWorkExperiencePoint}
                            />
                        } fileName="CRS-Breakdown.pdf">
                            {({ blob, url, loading, error }) => (
                                loading ? 'Loading document...' : 
                                <FontAwesomeIcon 
                                    icon={faDownload} 
                                    className={`download-icon${loading ? '.loading' : ''}`} 
                                />
                            )}
                        </PDFDownloadLink> 
                    </div>
                </div>
            </div>
            )}

        </div>
    );
};

export default ScoreCalculator;