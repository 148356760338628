import React, { useState } from 'react';
import './css/legalDisclaimer.css';
import { useTranslation } from 'react-i18next';

const LegalDisclaimer = () => {
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleAgreeButtonClick = () => {
    if (checkboxChecked) {
      // Handle the user's agreement, for example, by closing the popup
      setShowPopup(false);
    } else {
      // Alert the user that they need to check the checkbox
      alert(t("legalDisclaimerAlert"));
    }
  };

  return (
    <div className="App">
      {showPopup && (
        <>
          <div className="popup-background"></div>
          <div className="popup">
            <h1 className='mb-2 font-bold'>{t("legalDisclaimerTitle")}</h1>
            <p>{t("legalDisclaimer1")}</p>
            <p>{t("legalDisclaimer2")}</p>
            <p>{t("legalDisclaimer3")}</p>
            <p>
              <b>{t("legalDisclaimer4")}</b> {t("legalDisclaimer5")}:
              <ul className='list-disc'>
                <li>{t("legalDisclaimerLi1")}</li>
                <li>{t("legalDisclaimerLi2")}</li>
                <li>{t("legalDisclaimerLi3")}</li>
                <li>{t("legalDisclaimerLi4")}</li>
                <li>{t("legalDisclaimerLi5")}</li>
              </ul>
              {t("legalDisclaimer6")}
            </p>
            <p>
              <b>{t("legalDisclaimer7")}:</b>
              <ul className='list-disc'>
                <li>{t("legalDisclaimerLi6")}</li>
                <li>{t("legalDisclaimerLi7")}</li>
                <li>{t("legalDisclaimerLi8")}</li>
              </ul>
              {t("legalDisclaimer8")}
            </p>

            <div className='agree'>
              <div className="checkbox-text">
                <input type="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange} />
                <span>{t("agreeTerms")}</span>
              </div>
              <div className='agreeButton'>
                <button className='flex items-center justify-center px-4 py-2 hover:text-white hover:bg-np-red' onClick={handleAgreeButtonClick}>{t("agree")}</button>
              </div>
            </div>

          </div>
        </>
      )}
    </div>
  );
}

export default LegalDisclaimer;


