import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { v4 as uuidv4 } from 'uuid';
import { FaTimes } from "react-icons/fa";

import '../../App.css';
import './css/Dashboard.css'
import { AiOutlineLogout } from "react-icons/ai";

import axios from 'axios';

export default function Dashboard() {
  const navigate = useNavigate();

  const [expressEntry, setExpressEntry] = useState([]);
  const [databaseType, setDatabaseType] = useState("");
  const [expressEntryEndPoint, setExpressEntryEndPoint] = useState('https://northern-pathways-backend.onrender.com/api/expressEntry');

  useEffect(() => {
    axios.get(expressEntryEndPoint)
      .then(response => {
        const rearrangedData = response.data.sort((a, b) => a.value - b.value);
        setExpressEntry(rearrangedData);
      })
      .catch(error => {
        console.error('Error fetching express entry status:', error);
      });
  }, [expressEntryEndPoint]);

  useEffect(() => {
    if (databaseType === "EN") {
      setExpressEntryEndPoint('https://northern-pathways-backend.onrender.com/api/expressEntry');
    } else if (databaseType === "TR") {
      setExpressEntryEndPoint('https://northern-pathways-backend.onrender.com/api/expressEntryTR');
    }

    setSelectedCategory("");
    setSelectedCategoryDetails(null);
  }, [databaseType]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryDetails, setSelectedCategoryDetails] = useState(null);

  const handleCategorySelect = (event) => {
    const selectedCategoryId = event.target.value;
    const selectedCategoryEntry = expressEntry.find(entry => entry.category === selectedCategoryId);
    setSelectedCategory(selectedCategoryEntry.category);
    setSelectedCategoryDetails(selectedCategoryEntry);
    console.log(selectedCategoryDetails)
  };  

  const [selectedDate, setSelectedDate] = useState(null);
  const [invitationNumber, setInvitationNumber] = useState('');
  const [crsPoint, setCrsPoint] = useState('');
  const [specificProgram, setSpecificProgram] = useState('');

  useEffect(() => {
    setSelectedCategory('');
    setSpecificProgram('');
    setSelectedDate(null);
    setInvitationNumber('');
    setCrsPoint('');
  }, [databaseType]);

  const handleSubmission = () => {
    // Check if all required fields are filled
    if (!selectedCategory || !selectedDate || !invitationNumber || !crsPoint) {
      alert('Please fill in all fields.');
      return;
    }

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
  
    // Format date differently based on databaseType
    const formattedDate = databaseType === "TR"
      ? selectedDate.toLocaleDateString('tr-TR', options)
      : selectedDate.toLocaleDateString(undefined, options);
  
    const intInvitationNumber = parseInt(invitationNumber);
    const intCrsPoint = parseInt(crsPoint);
  
    let newId = uuidv4().replace(/-/g, '');
  
    if (newId.length > 24) {
      newId = newId.slice(0, 24);
    }

    const isSpecificProgram = selectedCategory === 'Program-specific Rounds (Such as PNP)' ||
                              selectedCategory === 'Program-specific Rounds (Eyalet Aday Programları gibi)';

    console.log(isSpecificProgram);
  
    const newDetail = {
      _id: newId,
      date: formattedDate,
      invitationNumber: intInvitationNumber,
      CRS: intCrsPoint,
      ...(isSpecificProgram && { subcategory: specificProgram  })
    };

    console.log("New", newDetail);
  
    const updatedDetails = [newDetail, ...selectedCategoryDetails.details.slice(0, -1)];

    console.log("Updated", updatedDetails);

    setSelectedCategoryDetails({ ...selectedCategoryDetails, details: updatedDetails });

    setSelectedDate(null);
    setInvitationNumber('');
    setCrsPoint('');
    if (isSpecificProgram) setSpecificProgram(''); 
  };
  
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/login'); // Redirect to the login page
  };

  const handleChange = () => {
    if (selectedCategoryDetails) {
      const apiEndpoint = databaseType === 'TR'
        ? 'https://northern-pathways-backend.onrender.com/api/updateExpressEntryTR'
        : 'https://northern-pathways-backend.onrender.com/api/updateExpressEntry';
      
      axios.post(apiEndpoint, selectedCategoryDetails)
        .then(response => {
          console.log('Database updated successfully:', response.data);
          alert('Database updated successfully');
        })
        .catch(error => {
          console.error('Error updating the database:', error);
          alert('Error updating the database');
        });
    } else {
      alert('No category details to update');
    }
  };

  const handleDeleteRow = (index) => {
    let updatedDetails = selectedCategoryDetails.details.filter((_, i) => i !== index);

    while (updatedDetails.length < 3) {
      updatedDetails.push({
        date: '',
        invitationNumber: '0',
        CRS: '0',
        subcategory: ''
      });
    }

    setSelectedCategoryDetails({ ...selectedCategoryDetails, details: updatedDetails });
  };


  return (
    <>
      <div className='min-h-screen'>
        <button onClick={handleLogout} className='absolute top-0 right-0 flex items-center justify-center text-sm font-bold text-black border-none hover:bg-transparent'>
            Logout
            <AiOutlineLogout className='ml-2' size={18}/>
        </button>
        <div className='flex flex-row justify-between mx-4 mt-12'>
          <div className='flex flex-col w-1/3'>
            <div className="flex justify-between mb-2">
            <button
              onClick={() => setDatabaseType("EN")}
              className={`w-1/2 p-2 bg-transparent border-2 mr-2 border-[#FF0000] hover:bg-[#FF0000] hover:text-white rounded-2xl ${databaseType === "EN" ? "!bg-[#FF0000] !text-white" : ""}`}
            >
              EN
            </button>
            <button
              onClick={() => setDatabaseType("TR")}
              className={`w-1/2 p-2 bg-transparent border-2 border-[#FF0000] hover:bg-[#FF0000] hover:text-white rounded-2xl ${databaseType === "TR" ? "!bg-[#FF0000] !text-white" : ""}`}
            >
              TR
            </button>
            </div>

            <select className="w-full p-2 mb-2 bg-transparent border-2 border-gray-400 rounded-2xl" value={selectedCategory} onChange={handleCategorySelect}  disabled={!databaseType}>
              <option value="" disabled>Select Category</option>
              {expressEntry.map(entry => (
                <option key={entry._id} value={entry.category}>{entry.category}</option>
              ))}
            </select>

            {(selectedCategory === 'Program-specific Rounds (Such as PNP)' || selectedCategory === 'Program-specific Rounds (Eyalet Aday Programları gibi)') && (
              <select
                className='w-full p-2 mb-2 bg-transparent border-2 border-gray-400 rounded-2xl'
                value={specificProgram}
                onChange={(e) => setSpecificProgram(e.target.value)}
              >
                <option value='' disabled>Select Program</option>
                <option value='Federal Skilled Worker Program'>Federal Skilled Worker Program</option>
                <option value='Federal Skilled Trades Program'>Federal Skilled Trades Program</option>
                <option value='Canadian Experience Class'>Canadian Experience Class</option>
                <option value='Provincial Nominee Program'>Provincial Nominee Program</option>
              </select>
            )}
            
            <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              className="w-full p-2 mb-2 bg-transparent border-2 border-gray-400 rounded-2xl"
              placeholderText="Select Date"
            />   
            
            <input 
              type="number" 
              value={invitationNumber}
              onChange={e => setInvitationNumber(e.target.value)}
              className="w-full p-2 mb-2 bg-transparent border-2 border-gray-400 rounded-2xl" 
              placeholder="Invitation Number" 
            />
            <input 
              type="number" 
              value={crsPoint}
              onChange={e => setCrsPoint(e.target.value)}
              className="w-full p-2 mb-2 bg-transparent border-2 border-gray-400 rounded-2xl" 
              placeholder="CRS Point" 
            />
            
            <div className='flex flex-row items-center justify-between'>
              <button onClick={handleSubmission} className='flex items-center justify-center py-2 text-white 2xl:px-8 sm:px-4 bg-np-red'>Add Table</button>
              <button onClick={handleChange} className='flex items-center justify-center px-8 py-2 mx-2 text-white 2xl:px-8 sm:px-4 bg-np-red'>Update Database</button>
            </div>
          </div>

          <div className='flex flex-col justify-center w-2/3 px-4 py-4 ml-4 border-2 border-gray-400 rounded-2xl'>
              <h1 className=''>Selected Category: {selectedCategory}</h1>
              
              {selectedCategoryDetails && (
                <table className="mt-4 border border-collapse border-gray-400">
                  <thead>
                    <tr>
                      <th className="p-2 border border-gray-400" style={{ width: '25.00%' }}>Date</th>
                      <th className="p-2 border border-gray-400" style={{ width: '25.00%' }}>Invitation Number</th>
                      <th className="p-2 border border-gray-400" style={{ width: '25.00%' }}>CRS Point</th>
                      {selectedCategoryDetails.details.some(detail => detail.subcategory) && (
                        <th className="p-2 border border-gray-400" style={{ width: '25.00%' }}>SubCategory</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCategoryDetails.details.map((detail, index) => (
                      <tr key={index}>
                        <td className="p-2 border border-gray-400" style={{ width: '25.00%' }}>{detail.date}</td>
                        <td className="p-2 border border-gray-400" style={{ width: '25.00%' }}>{detail.invitationNumber}</td>
                        <td className="p-2 border border-gray-400" style={{ width: '25.00%' }}>{detail.CRS}</td>
                        {detail.subcategory && (
                            <td>{detail.subcategory}</td>
                        )}
                        <td className="p-2 border border-gray-400">
                          <button onClick={() => handleDeleteRow(index)} className="text-red-500 border-none" style={{ width: '5%' }}><FaTimes /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          </div>
        </div>
      </div>
    </>
  );
}