import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';

import logo from '../components/images/logoFooter.png'
import location from '../components/images/location-pin2.svg'

function Footer () {
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();

    return (
        <>
            <div className='footer'>
                <hr className='separator'></hr>
                <div className='footer-logo'>
                    <Link to='https://www.northernpathways.ca' target='_blank' rel='noopener noreferrer'>
                        <img src={logo} alt='Footer Logo'></img>
                    </Link>
                </div>
                <div className='flex flex-row mt-2'>
                    <i className='location-icon' >
                        <img src={location} alt='Location Pin'></img>
                    </i>     
                    <div className='flex flex-col text-[14px] '>
                        <span className='address'>5200 Yonge Street, 2nd floor</span> 
                        <span className='address'>North York, ON</span>
                        <span className='address'>M2N 5P6</span>
                    </div>               

                </div>
                <p className='copyright rights text-[18px]'>&copy; {currentYear} Northern Pathways Immigration Consulting Inc. {t("copyright")}.</p>
            </div>
        </>
    )
}

export default Footer;