import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import logo from '../components/images/logoNavBar.png'
import instagram from '../components/images/instagram.svg'
import facebook from '../components/images/facebook.svg'
import youtube from '../components/images/youtube.svg'
import linkedin from '../components/images/linkedin.svg'

import LanguageSelector from './LanguageSelector';

import { useTranslation } from 'react-i18next';

function Navbar () {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);
    

    const { t } = useTranslation();
    
    return (
        <>
            <div className='top-bar'>
                <LanguageSelector />

                <div className='nav'>
                    <Link to='https://www.northernpathways.ca' target='_blank' rel='noopener noreferrer'>
                        <img className='navbar-logo' src={logo} alt='Header Logo'></img>
                    </Link>
                </div>
            </div>


            <nav className='navbar'>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>

                
                <ul className={click ? 'nav-menu active' : 'nav-menu flex-1'}>
                    <li className='nav-item'>
                        <Link to='/crstool' className='nav-links' onClick={closeMobileMenu}>
                            {t("crs")}
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/federal-skilled-worker' className='nav-links' onClick={closeMobileMenu}>
                            {t("FSWPNavbar")}
                        </Link>
                    </li> 
                    <li className='nav-item'>
                        <Link to='https://www.northernpathways.ca/pre-assessment-form'  target='_blank' rel='noopener noreferrer' className='nav-links' onClick={closeMobileMenu}>
                            {t("pre")}
                        </Link>
                    </li>
                </ul>
            </nav>

        </>
    )
}

export default Navbar;