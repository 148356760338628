import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router'; // Import useNavigate from react-router
import ReactGA from 'react-ga4';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import CRS from './components/pages/crsTool.js';
import FSWP from './components/pages/fswpTool.js';
import Login from './components/pages/Login.js';
import Dashboard from './components/pages/Dashboard.js';


// Functional component to handle redirection to '/crs-tool'
const RedirectToCRSTool = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/crstool', { replace: true });
  }, [navigate]);
  return null;
};

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

function App() {
  ReactGA.initialize ([
    {
      trackingId: "G-HR8PZ0BH40",
    },
  ]);

  ReactGA.send({ hitType: "pageview", page: "/" });
  ReactGA.send({ hitType: "pageview", page: "/crs-tool" });

  const location = useLocation();
  const [shouldRenderNavbarAndFooter, setShouldRenderNavbarAndFooter] = useState(true);

  useEffect(() => {
    // Check if the current location is 'login' or 'dashboard'
    if (location.pathname === '/login' || location.pathname === '/dashboard') {
      setShouldRenderNavbarAndFooter(false);
    } else {
      setShouldRenderNavbarAndFooter(true);
    }
  }, [location]);

  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <>
      {shouldRenderNavbarAndFooter && <Navbar />}
      <Routes>
        <Route path='/crstool' element={<CRS />} />
        <Route path='/federal-skilled-worker' element={<FSWP />} />
        <Route path='/login' element={<Login />} />
        <Route
          path='/dashboard'
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route path='*' element={<RedirectToCRSTool />} />
      </Routes>
      {shouldRenderNavbarAndFooter && <Footer />}
    </>
  );
}

export default AppWithRouter;
