import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';
import ENFlag from './images/ca.svg';
import TRFlag from './images/tr.svg';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [languageChanged, setLanguageChanged] = useState(false);

  const saveSelectedLanguage = (lng) => {
    localStorage.setItem('selectedLanguage', lng);
  };

  const getSelectedLanguage = () => {
    return localStorage.getItem('selectedLanguage') || 'en';
  };

  const handleLanguageChange = async (lng) => {
      i18n.changeLanguage(lng);
      saveSelectedLanguage(lng);
      setLanguageChanged(true);
  
      closeDropdown();
  };
  

  useEffect(() => {
    const storedLanguage = getSelectedLanguage();
    i18n.changeLanguage(storedLanguage);
    setLanguageChanged(false); // Reset the flag
  }, [i18n, languageChanged]);
  
  useEffect(() => {
    if (languageChanged) {
      // Reload the page only if the language was changed
      window.location.reload();

    }
  }, [languageChanged]);

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const getFlagImage = (languageCode) => {
    switch (languageCode) {
      case 'en':
        return ENFlag;
      case 'tr':
        return TRFlag;
      default:
        return null;
    }
  };

  const selectedLanguageCode = i18n.language.substring(0, 2);

  return (
    <div className="language-selector" onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
      <div className="selected-language">
        <img src={getFlagImage(selectedLanguageCode)} alt={`${selectedLanguageCode} Flag`} />
        {selectedLanguageCode.toUpperCase()}
      </div>
      {isDropdownOpen && (
        <div className="language-dropdown">
          <div className="language-option" onClick={() => handleLanguageChange('en')}>
            <img src={ENFlag} alt="English Flag" />
            EN
          </div>
          <div className="language-option" onClick={() => handleLanguageChange('tr')}>
            <img src={TRFlag} alt="Turkish Flag" />
            TR
          </div>
          {/* Add more language options as needed */}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
